import React, { Fragment, useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form'
import find from 'lodash/find';
import ImageUploader from 'react-images-upload';
import Breadcrumb from '../common/breadcrumb';
import logo from '../../assets/images/logo.png';
import { createUser, editUser, uploadAvatar } from '../../services/member.service'
import firebase from '../../data/base';
import RichEditor from '../editor/editor';
import useImageUpload from '../../hooks/useImageUpload'

const db = firebase.firestore();

const TeamUser = (props) => {
    const [picture, setPicture] = useState(null)
    const [imageUploaded, setImageUploaded] = useImageUpload(picture, { onSuccess: toast.success, onError: toast.error })
    const { register, handleSubmit, errors, control } = useForm(); // initialise the hook

    // EDIT
  
    const [data, setData] = useState({});   // defaults for EDIT
    const [userId, setUserId] = useState();
    const id = props.match.params.id;

    // Check for is edit
    useEffect(() => {
        if (id) {
            setUserId(id);
            const isSubscribed = db.collection("client_team").doc(id)
                .onSnapshot(function (doc) {
                    const user = doc.data();
                    if (doc.exists) {
                        setData(user);
                        setImageUploaded(user.avatar)
                    } else {
                        toast.warn('No searched member in database.')
                        props.history.push('/team/members');
                    }
                });
            return () => isSubscribed();
        }
    }, [db, id]);

    const onSubmit = data => {
        if (data !== '') {
            // if ID exist, its editing
            if (id) {
                editUser(data, imageUploaded, userId);
                toast.success(`Member Added!`)
            } else {
                createUser(data, imageUploaded);
                toast.success(`Member Edited`)
            }

            props.history.push('/team/members');
        } else {
            errors.showMessages();
        }
    };

    const renderMembers = () => {
        props.history.push('/team/members');
    }

    // DEFAULT VALUE for EDIT, if available
    const defaultName = data.name;
    const defaultTitle = data.title;
    const defaultMobile = data.mobile;
    const defaultSummary = data.summary;
    const defaultEmail = find(data.social_media, { name: 'email' });
    const defaultLinkedin = find(data.social_media, { name: 'linkedin' });
    const defaultTwitter = find(data.social_media, { name: 'twitter' });
    const defaultFacebook = find(data.social_media, { name: 'facebook' });
    const defaultInstagram = find(data.social_media, { name: 'instagram' });


    return (
        <Fragment>
            <Breadcrumb title="Edit User" parent="Contact" />
            <div className="container-fluid">
                <div className="">
                    <div className="authentication-box contact-profile-form">
                        <div className="text-center">
                            <img src={logo} alt="logo" /></div>
                        <div className="card mt-4">
                            <div className="card-body">
                                <div className="text-center user-profile">
                                    <div className="hovercard">
                                        <div className="contact-profile">
                                            <ImageUploader
                                                // defaultImages={}
                                                withIcon={true}
                                                withPreview={true}
                                                label={picture && !imageUploaded ? 'Uploading, please wait!' : '' }
                                                singleImage={true}
                                                buttonText="Upload Images"
                                                onChange={setPicture}
                                                imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif", ".svg"]}
                                                maxFileSize={5242880}
                                                fileSizeError=" file size is too big"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <label className="col-form-label pt-0">Name</label>
                                        <input className="form-control"
                                            defaultValue={defaultName}
                                            type="text" name="name"
                                            ref={register({ required: true })}
                                        />
                                        {errors.name && 'Name is required'}
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Title</label>
                                        <input className="form-control"
                                            defaultValue={defaultTitle}
                                            type="text" name="title"
                                            ref={register({ required: true })}
                                        />
                                        {errors.title && 'Title is required'}
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Mobile</label>
                                        <input className="form-control"
                                            defaultValue={defaultMobile}
                                            type="number" name="mobile"
                                            ref={register({ pattern: /\d+/, minlength: 0, maxlength: 9 })}
                                        />
                                        {errors.mobile && 'Please enter number max 9 digit'}
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Email</label>
                                        <input className="form-control"
                                            defaultValue={defaultEmail ? defaultEmail.value : ''}
                                            type="email" name="social_email"
                                            ref={register({ pattern: /\S+@\S+\.\S+/ })}
                                        />
                                        {errors.social_email && 'Email address is invalid.'}
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Linkedin</label>
                                        <input className="form-control"
                                            defaultValue={defaultLinkedin ? defaultLinkedin.value : ''}
                                            type="url" name="social_linkedin"
                                            ref={register}
                                        />
                                        {errors.social_email && 'Linkedin URL is invalid.'}
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Twitter</label>
                                        <input className="form-control"
                                            defaultValue={defaultTwitter ? defaultTwitter.value : ''}
                                            type="url" name="social_twitter"
                                            ref={register}
                                        />
                                        {errors.social_twitter && 'Twitter URL is invalid.'}
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Facebook</label>
                                        <input className="form-control"
                                            defaultValue={defaultFacebook ? defaultFacebook.value : ''}
                                            type="url" name="social_fb"
                                            ref={register}
                                        />
                                        {errors.social_fb && 'Facebook URL is invalid.'}
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Instagram</label>
                                        <input className="form-control"
                                            defaultValue={defaultInstagram ? defaultInstagram.value : ''}
                                            type="url" name="social_ig"
                                            ref={register}
                                        />
                                        {errors.social_ig && 'Instagram URL is invalid.'}
                                    </div>
                                    <div className="form-group mb-0">
                                        <label htmlFor="summary">Summary</label>
                                        <Controller
                                            name="summary"
                                            control={control}
                                            defaultValue={defaultSummary ? defaultSummary : ''}
                                            render={({ onChange, value}) => {
                                                return (
                                                    <RichEditor
                                                        content={value || defaultSummary}
                                                        setContent={onChange}
                                                    />
                                                )
                                            }}
                                        />
                                        {/* <textarea
                                            className="form-control"
                                            defaultValue={defaultSummary}
                                            id="summary"
                                            name="summary"
                                            rows="3"
                                            ref={register}
                                        >
                                        </textarea> */}
                                    </div>


                                    <div className="form-group form-row mt-3 mb-0">
                                        <button
                                            className="btn btn-primary mr-2"
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                        <button className="btn btn-primary"
                                            defaultValue={data.name}
                                            type="button" data-original-title="btn btn-info-gradien" title="" onClick={renderMembers}>
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default TeamUser;