import firebase from '../data/base';
import { _uploadFileStorage } from './blog.service';
import user from '../assets/images/user/user.png';

const db = firebase.firestore();

// upload attachment
export const uploadAvatar = async (
    file = null,
    {
        isRunning = () => { },
        onEachComplete = () => { },
        onComplete = () => { },
        onError = () => { }
    }
) => {
    // get user
    var user = firebase.auth().currentUser;
    if (user && file) {
        // NOTE, REACT IMG UPLOADED DOES NOT HAVE META, MAKE ONE
        const _file = {
            file, // actual file
            meta: {
                name: file.name // name of the file
            }
        }
        // wait for all files to complete
        await Promise.all(
            [
                _uploadFileStorage(user.uid, 'member_avatar', _file, { onEachComplete, onError, isRunning }) // wait for it to complete then next file
            ]
        ).then(onComplete).catch(err => onError(err));
    }
}


export const createUser = (value, avatar) => {

    const social = [];

    if (value.social_email) social.push({ name: "email", value: value.social_email });
    if (value.social_fb) social.push({ name: "facebook", value: value.social_fb });
    if (value.social_ig) social.push({ name: "instagram", value: value.social_ig });
    if (value.social_linkedin) social.push({ name: "linkedin", value: value.social_linkedin });
    if (value.social_twitter) social.push({ name: "twitter", value: value.social_twitter });

    db.collection('client_team').add({
        priority: false,
        avatar: avatar ? avatar : null,
        name: value?.name,
        title: value?.title,
        mobile: value?.mobile,
        social_media: social,
        summary: value?.summary,
        nameToSearch: value?.name.toLowerCase()
    })
}

export const deletedUser = (userId) => {
    return firebase.firestore().collection('client_team').doc(userId).delete();
}

export const editUser = (value, avatar, userId) => {
    const social = [];

    if (value.social_email) social.push({ name: "email", value: value.social_email });
    if (value.social_fb) social.push({ name: "facebook", value: value.social_fb });
    if (value.social_ig) social.push({ name: "instagram", value: value.social_ig });
    if (value.social_linkedin) social.push({ name: "linkedin", value: value.social_linkedin });
    if (value.social_twitter) social.push({ name: "twitter", value: value.social_twitter });

    db.collection('client_team').doc(userId).update({
        avatar: avatar ? avatar : null,
        name: value.name,
        title: value.title,
        mobile: value.mobile,
        social_media: social,
        summary: value.summary,
        nameToSearch: value.name.toLowerCase()
    })
}

export const markPriority = (value, userId) => {
    db.collection('client_team').doc(userId).update({
        priority: value
    });
}
