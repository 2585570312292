import React, { useEffect, Fragment, useState } from 'react';
import search from '../../assets/images/search-not-found.png';
import Breadcrumb from '../../components/common/breadcrumb';
import firebase from '../../data/base';
import moment from 'moment';

import { deleteInvitation } from '../../services/express.service';
const db = firebase.firestore();

const EditInvitation = (props) => {
    const [invitation, setInvite] = useState([]);

    useEffect(() => {
        const unsubscribe = db
            .collection('express_entry')
            .orderBy('round', 'desc')
            .onSnapshot((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }))
                setInvite(data);
            })
        return () => unsubscribe();
    }, [db]);


    const redirectUrl = () => {
        props.history.push(`${process.env.PUBLIC_URL}/expressEntry/add-invitation`);
    }

    const editInvite = (inv) => {
        props.history.push(`${process.env.PUBLIC_URL}/expressEntry/edit-invitation/${inv.id}`);
    }

    const deleteInvite = ({ id }) => {
        deleteInvitation(id)
    }


    return (
        <Fragment>
            <Breadcrumb title="Rounds of Invitation" parent="Express Entry" />
            <div className="container-fluid product-wrapper">
                <div className="product-grid">
                    <div className="card">
                        <div className="card-body">
                            <div className="col-xl-6 offset-xl-6">
                                <div className="contact-filter pull-right">
                                    <button className="btn btn-primary ml-4" onClick={redirectUrl}>
                                        ADD
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="product-wrapper-grid">
                    <div className="row">
                        {invitation.length > 0 ?
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="product-box table-responsive contact-table">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <h5 className="mb-0">Round</h5>
                                                    </th>
                                                    <th>
                                                        <h5 className="mb-0">Program</h5>
                                                    </th>
                                                    <th>
                                                        <h5 className="mb-0">Lowest-Ranked</h5>
                                                    </th>
                                                    <th >
                                                        <h5 className="mb-0">Number Invited</h5>
                                                    </th>
                                                    <th >
                                                        <h5 className="mb-0">Date</h5>
                                                    </th>
                                                    <th colSpan="2">
                                                        <h5 className="mb-0">Actions</h5>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {invitation.map((inv, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <h6><strong>{inv.round}</strong></h6>
                                                            </td>
                                                            <td>
                                                                <h6>{inv.program}</h6>
                                                            </td>
                                                            <td>
                                                                <h6>{inv.lowest_rank}</h6>
                                                            </td>
                                                            <td>
                                                                <h6>{inv.number_invited}</h6>
                                                            </td>
                                                            <td>
                                                                <h6>{moment.utc(inv.date).format('dddd, MMMM Do YYYY, h:mm:ss a')} UTC</h6>
                                                            </td>
                                                            <td><button className="btn btn-primary mr-2" onClick={() => editInvite(inv)} >Edit</button>
                                                                <button className="btn btn-secondary" onClick={() => deleteInvite(inv)}>Delete</button></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                    </div>

                                </div>
                            </div>
                            :
                            <div className="col-sm-12">
                                <div>
                                    <div className="search-not-found text-center">
                                        <div>
                                            <img src={search} alt="" className="second-search" />
                                            <p className="mb-0">Sorry, We didn't find any matching record.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </Fragment >
    );
};

export default EditInvitation;