
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import firebase from '../data/base';

const db = firebase.firestore();

// Service: string - firestore doc
const useServiceContents = document => {
    const [content, setContent] = useState('')

    const updateContent = (params) => {
        db.collection('client_services').doc(document).update(params).then(() => {
            toast.success('Content updated!')
        }).catch((error) => {
            console.error(error)
            toast.error('Unable to update content!')
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            const docRef = db.collection('client_services').doc(document);
            const doc = await docRef.get();
            if (doc.exists) {
                const data = doc.data();
                setContent(data);
            } else {
                console.error('Can not find doc of ', doc);
                toast.error('Unable to fetch content from database!')
            }
        }
        if (document) {
            fetchData()
        }
    }, [document])

    return [content, updateContent]
}

export default useServiceContents