import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../../common/breadcrumb';

import { DollarSign, Tag, ShoppingBag, MessageCircle, MinusCircle, ThumbsUp, MessageSquare, Briefcase, MoreHorizontal, Send, Activity, Anchor, Compass, Cpu, Slack, Umbrella, Box, Book } from 'react-feather';
import { calcultionOptions, calcultionData } from '../../../charts-data/default'
import ChartistGraph from 'react-chartist';
import EventCharts from './eventCharts';
import configDB from '../../../data/customizer/config';

import ClockWidget from '../../widgets/clock-widget';
import GooglDetail from '../../widgets/google-detail';

import app from "../../../data/base";

var Knob = require('knob')// browserify require
var primary = localStorage.getItem('primary_color') || configDB.data.color.primary_color;

const Default = ({ CurrentUser }) => {
    return (
        <Fragment>
            <Breadcrumb parent="Dashboard" title="Homepage" />
            <div className="container-fluid">
                <ClockWidget />
                <GooglDetail />
                
            </div>
        </Fragment>
    );
};

const mapStateToProps = ({ CurrentUser }) => {
    return { CurrentUser };
}

export default connect(
    mapStateToProps, {}
)(Default);
