import firebase from '../data/base';


export const updatePassword = password => {
    var user = firebase.auth().currentUser;
    
    return user.updatePassword(password);
}

export const updateProfile = ({ password, displayName }) => {
    var user = firebase.auth().currentUser;

    return user.updateProfile({
        displayName,
        photoURL: "https://example.com/jane-q-user/profile.jpg"
    });
}
