import firebase, { db, storage } from '../data/base';
import moment from 'moment';
import slugify from 'slugify';
import uid from 'uid';

// promise action
export const _uploadFileStorage = (userID, path = 'files', file, {
    isRunning, onEachComplete, onError
}) => {
    return new Promise((resolve, reject) => {
        const time = moment();
        const year = time.format('YYYY');
        const month = time.format('MM');
        const day = time.format('DD');

        let storageRef = storage.ref(`${path}/user-${userID}/${year}/${month}/${day}/${file.meta && file.meta.name}`);
        // upload
        let uploadTask = storageRef.put(file.file);
        // status check
        uploadTask.on('state_changed', snapshot => {
            // progress
            isRunning(snapshot);
        }, err => {
            // error
            console.error(err);
            onError(err);   // error
            reject(err);    // promise all err
        }, () => {
            // complete
            uploadTask.snapshot.ref.getMetadata().then(async meta => {
                onEachComplete(meta);   // complete for each item
                resolve(meta);  // resolve this and mark as done
            });
        });
    });
}

// TODO: promise action
// const _fetchFile = path => {
//     return new Promise((resolve, reject) => {
//         let storageRef = storage.ref(path);
//         // upload
//         let uploadTask = storageRef.put(file.file);
//         // status check
//         uploadTask.on('state_changed', snapshot => {
//             isRunning(snapshot);
//         }, err => {
//             // error
//             console.error(err);
//             onError(err);   // error
//             reject(err);    // promise all err
//         }, () => {
//             // complete
//             uploadTask.snapshot.ref.getMetadata().then(meta => {
//                 onEachComplete(meta);   // complete for each item
//                 resolve(meta);  // resolve this and mark as done
//             });
//         });
//     });
// }

// upload attachment
export const uploadAttachment = async (
    files = [],
    {
        isRunning = () => { },
        onEachComplete = () => { },
        onComplete = () => { },
        onError = () => { }
    }
) => {
    // get user
    var user = firebase.auth().currentUser;

    if (user && files.length > 0) {
        // wait for all files to complete
        await Promise.all(
            files.map(file => _uploadFileStorage(user.uid, 'attachments', file, { onEachComplete, onError, isRunning }) // wait for it to complete then next file
            )
        ).then(onComplete).catch(err => onError(err));
    }
}

// upload image
export const uploadImg = async (
    file = null,
    {
        isRunning = () => { },
        onEachComplete = () => { },
        onComplete = () => { },
        onError = () => { }
    }
) => {
    // get user
    var user = firebase.auth().currentUser;
    if (user && file) {
        // NOTE, REACT IMG UPLOADED DOES NOT HAVE META, MAKE ONE
        const _file = {
            file, // actual file
            meta: {
                name: file.name // name of the file
            }
        }
        // wait for all files to complete
        await Promise.all(
            [
                _uploadFileStorage(user.uid, 'article_images', _file, { onEachComplete, onError, isRunning }) // wait for it to complete then next file
            ]
        ).then(onComplete).catch(err => onError(err));
    }
}

// export const fetchAttachment = async (
//     files = [],
//     {
//         onComplete = () => {}
//     }
// ) => {
//     // get user
//     var user = firebase.auth().currentUser;

//     if (user && files.length > 0) {

//         // wait for all files to complete
//         await Promise.all(
//             files.map(file =>  {

//                 }
//             )
//         ).then(onComplete).catch(err => onError(err));
//     }
// }

const slugifyURL = content => slugify(`${moment().format('YYYY-MM')}-${content || ''}-${uid(1)}`, { lower: true });

// publish blog
export const submitBlog = (
    postFields,
    {
        onComplete = () => { },
        onError = () => { }
    }
) => {
    const {
        title, summary, preview_img, category, body, attachments
    } = postFields;

    // get user
    var user = firebase.auth().currentUser;
    if (user) {
        const ref = db.collection('article_blog').doc();
        const data = {
            title: title || '',
            seo_title: slugifyURL(title),
            summary: summary || '',
            preview_img: preview_img || null,
            category: category || [],
            author: user.displayName || '',
            uid: user.uid || '',
            body: body || '',
            attachments: attachments || [],
            published: false,
            timeCreated: moment().format(),
            last_edited: moment().format()
        };
        // set data
        ref.set(data)
            .then(status => {
                onComplete();
            })
            .catch(err => {
                console.error(err);
                onError(err);
            });
    }
};

export const saveBlog = (
    blogID,
    postFields,
    {
        onComplete = () => { },
        onError = () => { }
    }
) => {
    const {
        title, summary, preview_img, category, body, attachments
    } = postFields;

    // get user
    var user = firebase.auth().currentUser;
    if (user) {
        const ref = db.collection('article_blog').doc(blogID || '');

        // rather change or not change, it will be fetched from database
        let data = {
            title: title || '', 
            seo_title: slugifyURL(title),
            summary: summary || '',
            category: category || [],
            body: body || '',
            last_edited: moment().format()
        };

        // check for which need to be updated
        if (preview_img !== null) {
            data.preview_img = preview_img;
        }
        // check if attachment reuploaded
        if (attachments !== null && attachments.length > 0) {
            data.attachments = attachments || [];
        }


        // set data
        ref.update(data)
            .then(status => {
                onComplete();
            })
            .catch(err => {
                console.error(err);
                onError(err);
            });
    }
};

export const publishBlog = (articleRef, blogId) => {
    firebase.firestore().collection(articleRef).doc(blogId).update({
        published: true,
        last_edited: moment().format()
    });
}

export const unlistBlog = (articleRef, blogId) => {
    firebase.firestore().collection(articleRef).doc(blogId).update({
        published: false,
        last_edited: moment().format()
    });
}

export const deleteBlog = (articleRef, blogId) => {
    return firebase.firestore().collection(articleRef).doc(blogId).delete();
}