import React, { useState, useEffect } from 'react';
// import CKEditors from "react-ckeditor-component";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const RichEditor = ({ content, setContent }) => {
    return (
        <CKEditor
            editor={ClassicEditor}
            config={{
                // plugins: [ImageInsert],
                // toolbar: ['insertImage'],
            }}
            data={content}
            onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
            }}
        />
        // <CKEditors
        //     activeclassName="p10"
        //     content={data}
        //     events={{
        //         "change": onChange
        //     }}
        // />
    );
}

export default RichEditor;