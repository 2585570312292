import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import man from '../../../assets/images/dashboard/user.png'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';

const UserPanel = () => {
    const currentUser = useSelector(content => content.CurrentUser.user);
    const url = '';
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={url ? url : man} alt="#" />
                    <div className="profile-edit">
                        <Link to={`${process.env.PUBLIC_URL}/users/userEdit`}>
                            <Edit />
                        </Link>
                    </div>
                </div>
                <h6 className="mt-3 f-14">{currentUser && currentUser.displayName}</h6>
                <p>Admin</p>
            </div>
        </Fragment>
    );
};



const mapStateToProps = ({ CurrentUser }) => {
    return { CurrentUser };
}

export default connect(
    mapStateToProps
)(UserPanel);
