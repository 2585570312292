import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
import RightSidebar from './common/right-sidebar';
import Footer from './common/footer';
import ThemeCustomizer from './common/theme-customizer'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './common/loader';


import app from "../data/base";

import {
    setUser
} from '../actions/user.action';



const AppLayout = ({ children, setUser }) => {
    useEffect(() => {
        app.auth().onAuthStateChanged(function (user) {
            setUser(user || {});
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <Loader />
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    <Header />
                    <Sidebar />
                    <RightSidebar />
                    <div className="page-body">
                        {children}
                    </div>
                    <Footer />
                    <ThemeCustomizer />
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

const mapStateToProps = ({ CurrentUser }) => {
    return { CurrentUser };
}

export default connect(
    mapStateToProps, {
    setUser
}
)(AppLayout);
