import React, { useEffect, Fragment, useState } from 'react';
import { useForm, Controller } from 'react-hook-form'
import Breadcrumb from '../../components/common/breadcrumb';
import firebase from '../../data/base';
import { toast } from 'react-toastify';
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { addInvitation, editInvitation } from '../../services/express.service';
const db = firebase.firestore();

const EditInvitation = (props) => {
    const { register, control, handleSubmit, errors } = useForm(); // initialise the hook

    const [data, setData] = useState({});   // defaults for EDIT
    const [programs, setPrograms] = useState([]);   // defaults for EDIT
    const [inviteID, setInviteID] = useState();


    const navigateBack = () => {
        props.history.push('/expressEntry/rounds_invitation');
    }

    const id = props.match.params.id;
    // Check for is edit
    useEffect(() => {
        if (id) {
            setInviteID(id);
            const isSubscribed = db.collection("express_entry").doc(id)
                .onSnapshot(function (doc) {
                    const invite = doc.data();
                    if (doc.exists) {
                        setData(invite);
                    } else {
                        toast.warn('No searched invitation in database.');
                        navigateBack();
                    }
                });
            return () => isSubscribed();
        }
    }, [db, id]);


    // fetch programs
    useEffect(() => {
        const fetchPrograms = async () => {
            const docRef = firebase
                .firestore()
                .collection('app_config')
                .doc('common');
            const snapshot = await docRef.get();

            if (snapshot.exists) {
                const data = snapshot.data();
                setPrograms(data.entry_programs || []);
            } else {
                console.error('Can not find program information.');
            }

        };

        fetchPrograms();

    }, []);

    const onSubmit = data => {
        if (data !== '') {
            // if ID exist, its editing
            if (id) {
                editInvitation(data, inviteID);
                toast.success(`Invitation Edited`)
            } else {
                addInvitation(data);
                toast.success(`Invitation Added!`)
            }
            navigateBack();
        } else {
            errors.showMessages();
        }
    };



    // DEFAULT VALUE for EDIT, if available
    const defaultRound = data.round;
    const defaultProgram = data.program;
    const defaultLowRank = data.lowest_rank;
    const defaultNumberInvite = data.number_invited;
    const defaultDate = data.date ? new Date(data.date) : new Date();

    return (
        <Fragment>
            <Breadcrumb title="Edit Rounds of Invitation" parent="Express Entry" />
            <div className="container-fluid">
                <div className="">
                    <div className="authentication-box contact-profile-form">
                        <div className="card mt-4">
                            <div className="card-body">
                                <form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <label className="col-form-label pt-0">Round</label>
                                        <input className="form-control"
                                            defaultValue={defaultRound}
                                            type="number" name="round"
                                            ref={register({ required: true, pattern: /\d+/, minlength: 0 })}
                                        />
                                        {errors.round && 'Round # is required'}
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Program</label>
                                        <select
                                            className="custom-select form-control"
                                            name="program"
                                            defaultValue={defaultProgram}
                                            ref={register({ required: true })}
                                        >
                                            <option>Select a Program</option>
                                            {
                                                programs.length > 0 ?
                                                    programs.map(p =>
                                                        <option key={p.name} value={p.name}>{p.name}</option>)
                                                    : <option value="N/A">N/A</option>
                                            }
                                        </select>

                                        {errors.program && 'Program is required'}
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Lowest Invited Rank</label>
                                        <input className="form-control"
                                            defaultValue={defaultLowRank}
                                            type="number" name="lowest_rank"
                                            ref={register({ required: true, pattern: /\d+/, minlength: 0, maxlength: 1000 })}
                                        />
                                        {errors.lowest_rank && 'Please enter 0 - 1000'}
                                    </div>

                                    <div className="form-group">
                                        <label className="col-form-label">Numbers of Invite</label>
                                        <input className="form-control"
                                            defaultValue={defaultNumberInvite}
                                            type="number" name="number_invite"
                                            ref={register({ required: true, pattern: /\d+/, minlength: 0, maxlength: 99999 })}
                                        />
                                        {errors.number_invite && 'Please enter 0 - 99999'}
                                    </div>

                                    <div className="form-group">
                                        <label className="col-form-label">Select Date with Time</label>
                                        <Controller
                                            defaultValue={defaultDate}
                                            control={control}
                                            name="date"
                                            render={({ onChange, onBlur, value }) => (
                                                <ReactDatePicker
                                                    className="form-control digits"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    showTimeSelect
                                                    timeIntervals={15}
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    placeholderText="Select date"
                                                />
                                            )}
                                        />
                                        {errors.date && 'Date is required.'}
                                    </div>
                                    <div className="form-group form-row mt-3 mb-0">
                                        <button
                                            className="btn btn-primary mr-2"
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                        <button className="btn btn-primary"
                                            defaultValue={data.name}
                                            type="button" data-original-title="btn btn-info-gradien" title="" onClick={navigateBack}>
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default EditInvitation;