import React, { useEffect, Fragment, useState } from 'react';
import { useForm } from 'react-hook-form'
import { SketchPicker } from 'react-color';
import Breadcrumb from '../../components/common/breadcrumb';
import firebase from '../../data/base';
import { Button, Alert, UncontrolledCollapse } from 'reactstrap';
import { toast } from 'react-toastify';

import { updateServices } from '../../services/services.service';

const EditServices = (props) => {
    const [sectionConfig, setSectionConfig] = useState({});
    const [services, setServices] = useState([]);

    const [isEditing, setEditing] = useState(false);
    const { register, handleSubmit, errors } = useForm(); // initialise the hook

    const [serviceEdit, setServiceEdit] = useState([]);
    const [sectionEdit, setSectionEdit] = useState({});

    useEffect(() => {
        const fetchServices = async () => {
            let docRef = firebase
                .firestore()
                .collection('client_services')
                .doc('services_page');

            await docRef.onSnapshot(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    setServices(data['services']);
                    setSectionConfig(data['section_config']);
                } else {
                    console.error("No services found or error.")
                }
            });
        }

        fetchServices();
    }, []);

    const handleEdit = () => {
        setServiceEdit(services);
        setSectionEdit(sectionConfig);
        setEditing(true);
    }

    const handleDiscard = () => {
        setEditing(false);
    }

    const handleAddRow = () => {
        setServiceEdit([
            ...serviceEdit,
            {
                title: null,
                title_link: null,
                summary: null,
                icon: null,
                icon_color: '',
            }
        ]);
    }

    const editField = (index, field, value) => {
        setServiceEdit([
            ...serviceEdit.slice(0, index),
            {
                ...serviceEdit[index],
                [field]: value
            },
            ...serviceEdit.slice(index + 1)
        ])
    }

    const handleRemoveService = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        setServiceEdit([
            ...serviceEdit.slice(0, index),
            ...serviceEdit.slice(index + 1),
        ]);
    }


    const handleSave = () => {
        updateServices(
            sectionEdit,
            serviceEdit,
            () => {
                setEditing(false);
                toast.success('Successfuly updated services and summary!')
            },
            err => toast.error('Failed to update: ', err)
        );
    }


    const renderDisplayTable = () => (
        <React.Fragment>
            <div className="card height-equal">
                <div className="card-header">
                    <h5>Section Summary</h5>

                </div>
                <div className="card-body">
                    <p>{sectionConfig.summary}</p>
                </div>
            </div>
            <div className="card">
                <div className="product-box table-responsive contact-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>
                                    <h5 className="mb-0">Icon</h5>
                                </th>
                                <th>
                                    <h5 className="mb-0">Title</h5>
                                </th>
                                <th>
                                    <h5 className="mb-0">Summary</h5>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {services.map((ser, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <i
                                                className={ser.icon}
                                                style={{
                                                    backgroundColor: ser.icon_color,
                                                    borderRadius: '100%',
                                                    fontSize: '1.6rem',
                                                    padding: '1rem'
                                                }}
                                            ></i>
                                        </td>
                                        <td>
                                            <h6>
                                                {ser.title}&nbsp;
                                                {ser.title_link ?
                                                    <a
                                                        href={ser.title_link}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        alt="Title Link">
                                                        <i className="fa fa-external-link"></i>
                                                    </a> : null}
                                            </h6>
                                        </td>
                                        <td>
                                            <h6>{ser.summary}</h6>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <button className="btn btn-primary mr-2" onClick={() => handleEdit()}>Edit</button>
        </React.Fragment>
    )

    const renderEditTable = () => {
        return (
            <form className="theme-form" onSubmit={handleSubmit(handleSave)}>
                <Alert color="info" className="dark">
                    <p>
                        For Icons, please refer to https://themes-pixeden.com/font-demos/7-stroke/
                    </p>
                    <p>Eg. <strong>pe-7s-portfolio</strong></p>
                </Alert>

                <div className="card height-equal">
                    <div className="card-header">
                        <h5>Section Summary</h5>

                    </div>
                    <div className="card-body">
                        <textarea
                            className="form-control"
                            rows={3}
                            defaultValue={sectionConfig.summary}
                            onChange={e => setSectionEdit({ summary: e.target.value })}
                            type="text" name='summary'
                            ref={register({ required: true })}
                        />
                        {errors.summary && 'Summary is required'}
                    </div>
                </div>

                <div className="card">
                    <div className="product-box table-responsive contact-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th
                                        style={{ width: '25%' }}
                                    >
                                        <h5 className="mb-0">Icon</h5>
                                    </th>
                                    <th
                                        style={{ width: '20%' }}
                                    >
                                        <h5 className="mb-0">Title</h5>
                                    </th>
                                    <th
                                        style={{ width: '45%' }}
                                    >
                                        <h5 className="mb-0">Summary</h5>
                                    </th>
                                    <th
                                        style={{ width: '10%' }}
                                    >
                                        <h5 className="mb-0">Remove</h5>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    serviceEdit.map((ser, index) => {
                                        return (
                                            <tr key={index}>
                                                <td
                                                    style={{ verticalAlign: 'baseline' }}
                                                >
                                                    <div>
                                                        <i
                                                            className={ser.icon}
                                                            style={{
                                                                backgroundColor: ser.icon_color,
                                                                borderRadius: '100%',
                                                                fontSize: '1.6rem',
                                                                padding: '1rem'
                                                            }}
                                                        ></i>
                                                    </div>
                                                    <div
                                                        style={{ margin: '2rem 0' }}
                                                    >
                                                        <label className="col-form-label">
                                                            Icon
                                                            </label>
                                                        <a href="https://themes-pixeden.com/font-demos/7-stroke/" rel="noopener noreferrer"
                                                            target="_blank"
                                                            alt="Icon"
                                                        ><i className="fa fa-external-link"></i></a>
                                                        <input className="form-control"
                                                            defaultValue={ser.icon}
                                                            onChange={e => editField(index, 'icon', e.target.value)}
                                                            type="text" name={`icon_${index}`}
                                                            ref={register({ required: true })}
                                                        />
                                                        {errors[`icon_${index}`] && 'Icon is required'}

                                                    </div>
                                                    <div>
                                                        <Button id="chooseColor" type="button" color="primary">
                                                            Choose color
                                                        </Button>
                                                        <UncontrolledCollapse toggler="#chooseColor">
                                                            <SketchPicker

                                                                color={ser.icon_color}
                                                                onChangeComplete={(color) => editField(index, 'icon_color', color.hex)}
                                                            />
                                                        </UncontrolledCollapse>
                                                    </div>
                                                </td>
                                                <td
                                                    style={{ verticalAlign: 'baseline' }}
                                                >
                                                    <label className="col-form-label">Title</label>
                                                    <textarea
                                                        className="form-control"
                                                        rows={3}
                                                        defaultValue={ser.title}
                                                        onChange={e => editField(index, 'title', e.target.value)}
                                                        type="text" name={`title_${index}`}
                                                        ref={register({ required: true })}
                                                    />
                                                    {errors[`title_${index}`] && 'Title is required'}


                                                    <label className="col-form-label">Link</label> <i className="fa fa-external-link"></i>
                                                    <input className="form-control"
                                                        defaultValue={ser.title_link}
                                                        onChange={e => editField(index, 'title_link', e.target.value)}
                                                        type="text" name={`title_link_${index}`}
                                                    />
                                                </td>
                                                <td
                                                    style={{ verticalAlign: 'baseline' }}
                                                >
                                                    <textarea
                                                        className="form-control"
                                                        rows={8}
                                                        defaultValue={ser.summary}
                                                        onChange={e => editField(index, 'summary', e.target.value)}
                                                        type="text" name={`summary_${index}`}
                                                        ref={register({ required: true })}
                                                    />
                                                    {errors[`summary_${index}`] && 'Smmary is required'}

                                                </td>
                                                <td
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    <a
                                                        href="#"
                                                        onClick={e => handleRemoveService(e, index)}
                                                        style={{ fontSize: '1.6rem' }}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td />
                                    <td />
                                    <td />
                                    <td>
                                        <button className="btn btn-primary mr-2" onClick={() => handleAddRow()}>Add Row</button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <React.Fragment>
                    <button className="btn btn-primary mr-2" type="submit">Save</button>
                    <button className="btn btn-secondary" onClick={() => handleDiscard()}>Discard</button>
                </React.Fragment>
            </form >
        );
    }

return (
    <Fragment>
        <Breadcrumb title="Edit Services" parent="Services" />
        <div className="container-fluid product-wrapper">
            <div className="product-grid">
                <div className="product-wrapper-grid">
                    <div className="row">
                        <div className="col-sm-12">
                            {
                                !isEditing ?
                                    renderDisplayTable() : renderEditTable()
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </Fragment >
);
};

export default EditServices;