import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import Breadcrumb from '../common/breadcrumb';
import { updateProfile, updatePassword } from '../../services/user.service'
import seven from '../../assets/images/user/user.png';

const UserEdit = () => {
    const currentUser = useSelector(content => content.CurrentUser.user);

    const [displayName, setDisplayName] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const updateUserPassword = () => {
        const response = updatePassword(newPassword);
        response.then(() => {
            setNewPassword('');
            toast.success("Password updated !");
        }).catch(err => {
            console.error(err);
            toast.error(err.message);
        });
    }

    const updateUser = () => {
        const response = updateProfile({
            displayName
        });
        response.then(() => {
            setDisplayName('');
            toast.success("Profile updated !");
        }).catch(err => {
            console.error(err);
            toast.error(err.message);
        });
    }

    const disablePasswordUpdate = newPassword === '' || newPassword.length < 6 || newPassword.length > 16;
    const disableProfileUpdate = displayName === '' || displayName.length < 3 || displayName.length > 32;

    return (
        <Fragment>
            <Breadcrumb parent="User" title="Edit Profile" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">My Profile</h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="row mb-2">
                                            <div className="col-auto"><img className="img-70 rounded-circle" alt="" src={seven} /></div>
                                            <div className="col">
                                                <h3 className="mb-1">{currentUser && currentUser.displayName}</h3>
                                                <p className="mb-4">Admin</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Email-Address</label>
                                            <input className="form-control" placeholder={currentUser && currentUser.email} readOnly />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Password</label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                value={newPassword}
                                                onChange={e => setNewPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="card-footer text-right">
                                            <button
                                                className={
                                                    classnames('btn btn-primary', {
                                                        disabled: disablePasswordUpdate
                                                    })
                                                }
                                                type="button"
                                                onClick={!disablePasswordUpdate ? updateUserPassword : null}
                                            >
                                                Update Password</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <form className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Edit Profile</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">Display Name</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={displayName}
                                                    onChange={e => setDisplayName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button
                                        className={
                                            classnames('btn btn-primary', {
                                                disabled: disableProfileUpdate
                                            })
                                        }
                                        type="button"
                                        onClick={!disableProfileUpdate ? updateUser : null}
                                    >
                                        Update Profile</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            
                <ToastContainer />
            </div>
        </Fragment>
    );
};


const mapStateToProps = ({ CurrentUser }) => {
    return { CurrentUser };
}

export default connect(
    mapStateToProps
)(UserEdit);