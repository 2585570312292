import firebase from '../data/base';

const db = firebase.firestore();

export const addInvitation = (value) => {
    db.collection('express_entry').add({
        round: value ? value.round : 0,
        program: value ? value.program : 'No program specified',
        lowest_rank: value ? value.lowest_rank : 0,
        number_invited: value ? value.number_invite : 0,
        date: value ? new Date(value.date).getTime() : null,
    });
}

export const deleteInvitation = (invId) => {
    return firebase.firestore().collection('express_entry').doc(invId).delete();
}

export const editInvitation = (value, invId) => {
    db.collection('express_entry').doc(invId).update({
        round: value ? value.round : 0,
        program: value ? value.program : 'No program specified',
        lowest_rank: value ? value.lowest_rank : 0,
        number_invited: value ? value.number_invite : 0,
        date: value ? new Date(value.date).getTime() : null,
    });
}

