
import React, { useState, useEffect } from 'react'
import { uploadAvatar } from '../services/member.service'

const useImageUpload = (picture, {
    onSuccess = () => {}, onError = () => {}
}) => {
    const [imageUploaded, setImageUploaded] = useState(null)

    useEffect(() => {
        if (picture && picture.length > 0) {
            let fileMetas = null;
            // start upload image , first in the list
            uploadAvatar(picture[0], {
                onEachComplete: meta => {
                    // save meta
                    fileMetas = {
                        contentType: meta.contentType,
                        fullPath: meta.fullPath,
                        name: meta.name,
                        size: meta.size,
                        type: meta.type,
                        timeCreated: meta.timeCreated,
                        updated: meta.updated
                    };
                    onSuccess(`${meta.name} uploaded!`)
                },
                onComplete: () => {
                    setImageUploaded(fileMetas)
                },   // set uploaded img metas
                onError: err => {
                    switch (err.code) {
                        case 'storage/unauthorized':
                            onError('No permission to upload!')
                            break;
                        case 'storage/canceled':
                            onError('Upload canceled!')
                            break;
                        case 'storage/unknown':
                            console.error("Image upload error: ");
                            console.error(err.serverResponse);
                            onError('Unknown error!')
                            break;
                    }
                }
            });
        }
    }, [picture])

    return [imageUploaded, setImageUploaded]
}

export default useImageUpload