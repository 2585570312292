import React, { Fragment, useState, useEffect } from 'react';
import classnames from 'classnames';
import Breadcrumb from "../../components/common/breadcrumb";
import RichEditor from '../../components/editor/editor';
import { toast } from 'react-toastify';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DateTimePicker from 'react-datetime-picker'
import useHomeAlert from '../../hooks/useHomeAlert'
import moment from 'moment'

const HomeAlertModal = () => {
    // informations
    const [data, setData] = useHomeAlert()

    const [content, setContent] = useState('')
    const [expiry_date, setExpiry] = useState(moment(new Date()).add(1, 'days').toDate())

    useEffect(() => {
        if (data) {
            setContent(data?.content || '')
            console.log("data?.expiry_date", data?.expiry_date)
            setExpiry(data?.expiry_date && data?.expiry_date?.toDate())
        }
    }, [data])

    const handleDiscard = () => {
        setContent(data?.content)
        setExpiry(data?.expiry_date && data?.expiry_date?.toDate())
        toast.success('Discarded!')
    }

    const handleDelete = () => {
        setContent('')
        setExpiry(moment(new Date()).add(1, 'days').toDate())
        setData({
            expiry_date: '',
            content: ''
        })
        toast.success('Deleted!')
    }

    const handlePost = () => {
        console.log("expiry_date", expiry_date)
        // prepare data
        setData({
            expiry_date: expiry_date,
            content: content
        })
    }

    return (
        <Fragment>
            <Breadcrumb title="Home Alert Modal" parent="Dashboard" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Home Alert Edit</h5>
                            </div>
                            <div className="card-body add-post">
                                <form className="row needs-validation" noValidate="">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="post_title">Expiry Date:</label>
                                            <div>
                                                <DateTimePicker
                                                    minDate={new Date()}
                                                    onChange={value => setExpiry(value)}
                                                    value={expiry_date}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <div className="theme-form">
                                                <div className="form-group">
                                                    <label>Content:</label>
                                                    <RichEditor
                                                        content={content}
                                                        setContent={setContent}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="btn-showcase" style={{ marginTop: '1rem' }}>
                                    <button
                                        className='btn btn-primary'
                                        type="button"
                                        onClick={handlePost}
                                    >
                                        Save
                                    </button>
                                    <input
                                        className="btn btn-light"
                                        type="reset"
                                        value="Discard"
                                        onClick={handleDiscard}
                                    />
                                    <input
                                        className="btn btn-warning"
                                        type="reset"
                                        value="Delete"
                                        onClick={handleDelete}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default HomeAlertModal;