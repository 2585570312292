import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import Breadcrumb from "../common/breadcrumb";
import firebase from '../../data/base';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Collapse } from 'reactstrap';
import { publishBlog, unlistBlog, deleteBlog } from '../../services/blog.service';

const BlogDetail = ({ history }) => {
    const [blogList, setBlogList] = useState([]);
    const [expandedID, setExpandedID] = useState(null);
    const [page, setPage] = useState(0);
    const [alert, setAlert] = useState(null);



    const fetchDownloadURL = async path => {
        if (path) {
            const storage = firebase.storage();
            const url = await storage.ref(path).getDownloadURL()
            return url;
        }
    }

    useEffect(() => {
        const unsubscribe = firebase
            .firestore()
            .collection('article_blog')
            .orderBy('timeCreated', 'desc')
            .onSnapshot((snapshot) => {
                const newItem = snapshot.docs.map(doc => {
                    const data = doc.data();

                    return ({
                        id: doc.id,
                        // attachmentURL: data.attachments.map(async item => fetchDownloadURL(item.fullPath)),
                        ...data
                    });
                });
                setBlogList(newItem)
            });
        return () => unsubscribe();
    }, []);

    const handlePublish = blog => {
        publishBlog('article_blog', blog.id);
        setAlert(null);
    }

    const handleUnlist = blog => {
        unlistBlog('article_blog', blog.id);
        setAlert(null);
    }

    const handleDelete = blog => {
        deleteBlog('article_blog', blog.id);
        setAlert(null);
    }

    const handleAlert = (type, blog) => {
        switch (type) {
            case 'publish':
                setAlert(
                    <SweetAlert
                        custom
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnText="No"
                        confirmBtnBsStyle="primary"
                        cancelBtnBsStyle="default"
                        customIcon="thumbs-up.jpg"
                        title="Publish Blog"
                        type="success"
                        onConfirm={() => handlePublish(blog)}
                        onCancel={() => setAlert(null)}
                    >
                        Publish the blog to public. You can undo this action with unlist after.
                        </SweetAlert>
                );
                break;
            case 'unlist':
                setAlert(
                    <SweetAlert
                        custom
                        showCancel
                        confirmBtnText="Unlist"
                        cancelBtnText="No"
                        confirmBtnBsStyle="primary"
                        cancelBtnBsStyle="default"
                        customIcon="thumbs-up.jpg"
                        title="Unlist Blog"
                        type="warning"
                        onConfirm={() => handleUnlist(blog)}
                        onCancel={() => setAlert(null)}
                    >
                        Unlisting the blog will hide from public view.
                        </SweetAlert>
                );
                break;
            case 'delete':
                setAlert(
                    <SweetAlert
                        custom
                        showCancel
                        confirmBtnText="Delete"
                        cancelBtnText="No"
                        confirmBtnBsStyle="primary"
                        cancelBtnBsStyle="default"
                        title="Delete this blog?"
                        type="danger"
                        onConfirm={() => handleDelete(blog)}
                        onCancel={() => setAlert(null)}
                    >
                        Blog will be removed permanently. You can also unlist to hide from viewing.
                        </SweetAlert>
                );
                break;
        }
    }

    const handleEdit = blog => {
        history.push(`${process.env.PUBLIC_URL}/blog/blogPost`, { isEdit: true, id: blog.id });
    }

    const fetchDownloadURL2 = item => {
        if (item) {
            const storage = firebase.storage();
            const data = storage.ref(item.fullPath).getDownloadURL()
                .then(url => {
                    return (
                        <a href={url}>
                            <i className="fa fa-cloud-download"></i>
                            {item.name}
                        </a>
                    )
                }).catch(err => {
                    return (
                        <Fragment>
                            <i className="fa fa-cloud-download"></i>
                            {item.name}
                        </Fragment>
                    )
                });

        }
    }

    const renderCategory = (list = []) => {
        return list.map((item, idx) =>
            <span key={`category_${idx}`} className="badge badge-pill badge-info">{item.name}</span>
        );
    }

    const renderAttachment = (list = []) => {
        return list.map((item, idx) =>
            <label key={`attachment_${idx}`} className="m-0">
                <Fragment>
                    <i className="fa fa-cloud-download"></i>
                    {item.name}
                </Fragment>
            </label>
        );
    }

    const renderCard = blog => {
        const isExpanded = expandedID === blog.id;

        return (
            <div key={`article_blog_${blog.id}`} className="col-sm-12">
                <div className="card ribbon-right-wrapper ">
                    <div className={classnames("card-header", {
                        'b-t-success': !blog.published,
                        'b-t-info': blog.published
                    })}
                    >
                        <h5>{blog.title}</h5>
                        <ul className="blog-social" >
                            <li className="digits">{moment(blog.timeCreated).format('LLLL')}</li>
                            <li><i className="icofont icofont-user"></i>{blog.author}</li>
                        </ul>
                        <div className="article_category" style={{ marginTop: '0.5rem' }}>
                            {renderCategory(blog.category)}
                        </div>
                    </div>
                    <div className="card-body" style={{ overflow: 'hidden' }}>
                        <div
                            className={classnames("ribbon ribbon-right ribbon-clip-right", {
                                'ribbon-success': blog.published,
                                'ribbon-warning': !blog.published,
                            })}>
                            {blog.published ? 'Published' : 'Not listed'}
                        </div>

                        <Collapse isOpen={isExpanded}>
                            <div dangerouslySetInnerHTML={{ __html: blog.body }} />
                            {renderAttachment(blog.attachments)}
                        </Collapse>

                        <div className="expand-article" style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Button
                                color='primary'
                                onClick={() => isExpanded ? setExpandedID(null) : setExpandedID(blog.id)}
                            >
                                {isExpanded ? 'Collapse' : 'Expand'} <i className="fa fa-expand"></i>
                            </Button>
                        </div>
                    </div>
                    <div className="card-footer" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            className="btn btn-pill btn-light btn-air-light btn-air-light"
                            type="button"
                            style={{ marginRight: '1rem' }}
                            onClick={() => handleEdit(blog)}
                        >
                            Edit
                            </button>
                        {!blog.published ?
                            <button
                                className="btn btn-pill btn-success btn-air-success btn-air-success"
                                type="button"
                                onClick={() => handleAlert('publish', blog)}
                            >
                                Publish
                            </button>
                            :
                            <button
                                className="btn btn-pill btn-warning btn-air-warning btn-air-warning"
                                type="button"
                                onClick={() => handleAlert('unlist', blog)}
                            >
                                Unlist
                            </button>
                        }
                        <button
                            className="btn btn-pill btn-danger btn-air-danger"
                            type="button"
                            style={{ marginLeft: '1rem' }}
                            onClick={() => handleAlert('delete', blog)}
                        >
                            Delete
                            </button>
                    </div>
                </div>
            </div>
        );

    }

    return (
        <Fragment>
            <Breadcrumb title="Blog List" parent="Blog" />
            <div className="container-fluid">
                <div className="row">
                    {/* render blogs */}
                    {blogList.map(renderCard)}

                </div>
                {/* Render alert for action */}
                {alert}
            </div>
        </Fragment>
    );
};

export default BlogDetail;