import {
    SET_USER_INFORMATION
} from '../constant/actionTypes';


const initial_state = {
    user: null
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case SET_USER_INFORMATION:
            return { ...state, user: action.payload }
        default: return { ...state };
    }
}
