import React, { useEffect, useState, useRef } from 'react';
import Ratings from 'react-ratings-declarative';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Star, Navigation, Briefcase, Home, Phone, Activity, Clock } from 'react-feather';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import styled from 'styled-components'

import app from "../../data/base";

const StyledDetailRow = styled.div`
    div {
        margin-bottom: 0.5rem;
    }
`

const GoogleDetail = () => {
    const [activeTab, setActiveTab] = useState('1');

    const [storedReview, setStoredReview] = useState([]);
    const [storedDetail, setStoredDetail] = useState({});

    const [isFetched, setFetch] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [detail, setDetail] = useState({});

    const google = window.google;
    const mapEl = useRef(null);

    useEffect(() => {
        const fetchReviews = async () => {
            let docRef = app
                .firestore()
                .collection('client_feedback')
                .doc('google_detail');

            await docRef.onSnapshot(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    // full set
                    setStoredDetail(data);
                    // just review
                    setStoredReview(data['reviews']);

                } else {
                    console.error("No services found or error.")
                }
            });
        }

        fetchReviews();
    }, []);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const fetchReview = () => {
        const map = new google.maps.Map(mapEl.current);

        const services = new google.maps.places.PlacesService(map);
        const request = {
            placeId: 'ChIJR9UQjlnS1IkR7YoauPQVBP4',
            fields: [
                'name',
                'rating',
                'user_ratings_total',
                'url',
                'opening_hours',
                'formatted_address',
                'formatted_phone_number',
                'reviews'
            ]
        };

        services.getDetails(request, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                toast.success('New reviews refreshed. Please review before publish.');
                // full set
                setDetail(place);
                // detail
                setReviews(place.reviews);
                setFetch(true);
            } else {
                toast.warn('Failed to get new reviews from google: ', status);
            }
        });

    };

    const deleteReview = (index) => {
        setReviews([
            ...reviews.slice(0, index),
            ...reviews.slice(index + 1)
        ]);
    }


    const cancelRefresh = () => {
        // full set
        setDetail({});
        // detail
        setReviews([]);
        setFetch(false);
    }

    const handlePublish = () => {
        app
            .firestore()
            .collection('client_feedback').doc('google_detail').update(
                {
                    ...detail,
                    opening_hours: {
                        periods: detail.opening_hours.periods,
                        weekday_text: detail.opening_hours.weekday_text
                    },
                    reviews: reviews
                },
            ).then(() => {
                toast.success('Published!')
                cancelRefresh();
            }
            ).catch((error) => toast.error('Error: ', error))
    }


    const renderBusinessHour = ({ weekday_text }) => {
        if (weekday_text) {
            return weekday_text.map((t, idx) => <span key={`hour_${idx}`}>{t}</span>);
        } else {
            return null;
        }
    }

    const _detail = isFetched ? detail : storedDetail;

    return (
        <div className="col-xl-12">
            <div className="card height-equal">
                <div className="card-header card-header-border">
                    <div className="row">
                        <div className="col-sm-12">
                            <h5>Google Details</h5>
                        </div>
                        <div className="col-sm-12">
                            <div className="pull-right right-header">
                                {
                                    !isFetched ?
                                        <span>
                                            <button
                                                className="btn btn-primary btn-pill"
                                                onClick={fetchReview}
                                            >Refresh</button>
                                        </span>
                                        : null
                                }
                                {
                                    isFetched ?
                                        <span>
                                            <button
                                                className="btn btn-primary btn-pill"
                                                onClick={handlePublish}
                                            >Publish</button>
                                        </span>
                                        : null
                                }
                                {
                                    isFetched ?
                                        <span>
                                            <button
                                                className="btn btn-primary btn-pill"
                                                onClick={cancelRefresh}
                                            >Discard</button>
                                        </span>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                            Reviews
                    </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                            Detail
                    </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="card-body">
                            <div id="google-map" ref={mapEl}></div>
                            <div className="new-users">
                                {!isFetched && storedReview && storedReview.map((r, idx) => (
                                    <div key={`review_${idx}`} className="media">
                                        <img className="rounded-circle image-radius m-r-15" src={r.profile_photo_url} alt="" />
                                        <div className="media-body">
                                            <h6 className="mb-0 f-w-700">
                                                <a href={r.author_url} target="_blank" rel="noopener noreferrer">{r.author_name}</a></h6>
                                            <p>{r.text}</p>
                                            <strong>{r.relative_time_description}</strong>
                                            <div className="rating-container digits rating-primary">
                                                <Ratings
                                                    rating={r.rating}
                                                    widgetDimensions="24px"
                                                >
                                                    <Ratings.Widget />
                                                    <Ratings.Widget />
                                                    <Ratings.Widget />
                                                    <Ratings.Widget />
                                                    <Ratings.Widget />
                                                </Ratings>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {isFetched && reviews && reviews.map((r, idx) => (
                                    <div className="media" key={`new_review_${idx}`}>
                                        <img className="rounded-circle image-radius m-r-15" src={r.profile_photo_url} alt="" />
                                        <div className="media-body">
                                            <h6 className="mb-0 f-w-700">
                                                <a href={r.author_url} target="_blank" rel="noopener noreferrer">{r.author_name}</a></h6>
                                            <p>{r.text}</p>
                                            <strong>{r.relative_time_description}</strong>
                                            <div className="rating-container digits rating-primary">
                                                <Ratings
                                                    rating={r.rating}
                                                    widgetDimensions="24px"
                                                >
                                                    <Ratings.Widget />
                                                    <Ratings.Widget />
                                                    <Ratings.Widget />
                                                    <Ratings.Widget />
                                                    <Ratings.Widget />
                                                </Ratings>
                                            </div>
                                        </div>
                                        <span className="pull-right">
                                            <button
                                                className="btn btn-pill btn-outline-light"
                                                onClick={() => deleteReview(idx)}
                                            >Delete</button>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="card-body">
                            <StyledDetailRow className="row">
                                <div className="col-md-6">
                                    <div className="media feather-main">
                                        <div className="feather-icon-block">
                                            <Briefcase />
                                        </div>
                                        <div className="media-body align-self-center">
                                            <h6>Name</h6>
                                            <a href={_detail.url} target="_blank">{_detail.name}</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="media feather-main">
                                        <div className="feather-icon-block">
                                            <Home />
                                        </div>
                                        <div className="media-body align-self-center">
                                            <h6>Address</h6>
                                            <p>{_detail.formatted_address}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="media feather-main">
                                        <div className="feather-icon-block">
                                            <Star />
                                        </div>
                                        <div className="media-body align-self-center">
                                            <h6>Rating</h6>
                                            <div className="rating-container digits rating-primary">
                                                
                                                <Ratings
                                                    rating={_detail.rating}
                                                    widgetDimensions="24px"
                                                >
                                                    <Ratings.Widget />
                                                    <Ratings.Widget />
                                                    <Ratings.Widget />
                                                    <Ratings.Widget />
                                                    <Ratings.Widget />
                                                </Ratings>
                                                <strong> - {_detail.rating}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="media feather-main">
                                        <div className="feather-icon-block">
                                            <Navigation />
                                        </div>
                                        <div className="media-body align-self-center">
                                            <h6>Total Rating</h6>
                                            <p>{_detail.user_ratings_total}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="media feather-main">
                                        <div className="feather-icon-block">
                                            <Phone />
                                        </div>
                                        <div className="media-body align-self-center">
                                            <h6>Phone</h6>
                                            <a href={`tel:${_detail.formatted_phone_number}`}>{_detail.formatted_phone_number}</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="media feather-main">
                                        <div className="feather-icon-block">
                                            <Clock />
                                        </div>
                                        <div className="media-body align-self-center">
                                            <h6>Openning Hours</h6>
                                            <p>
                                                {
                                                    renderBusinessHour(_detail.opening_hours || {})
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </StyledDetailRow>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </div >
    )
}

export default GoogleDetail;