import {
    Home,
    DollarSign,
    UserPlus,
    Users,
    Chrome,
    Settings,
    Airplay,
    Slack,
    FolderPlus,
    File,
    Command, Cloud, Book, FileText, Server, Image, Sliders, Map, GitPullRequest, Calendar, Edit, Mail, MessageSquare, UserCheck, Layers, HelpCircle, Database, Headphones, Mic, ShoppingBag, Search, AlertOctagon, Lock, Briefcase, BarChart
} from 'react-feather';

export const MENUITEMS = [
    {
        title: 'Dashboard', icon: Home, type: 'sub', badgeType: 'primary', active: false, children: [
            { path: '/dashboard/main', title: 'Main', type: 'link' },
            { path: '/dashboard/home-alert', title: 'Alert Modal', type: 'link' }
        ]
    },
    {
        title: 'Services', icon: DollarSign, type: 'sub', active: false, children: [
            { path: '/services/study-permit', title: 'Study Permit', type: 'link' },
            { path: '/services/care-giver', title: 'Care Giver', type: 'link' },
            { path: '/services/express-entry', title: 'Express Entry', type: 'link' },
            { path: '/services/family-sponsor', title: 'Family Sponsor', type: 'link' },
            { path: '/services/citizenship', title: 'Citizenship', type: 'link' },
            { path: '/services/temporary-visa', title: 'Temporary Visa', type: 'link' },
            { path: '/services/notary-public', title: 'Notary Public', type: 'link' },
            { path: '/services/fingerprinting', title: 'Fingerprinting', type: 'link' },
            { path: '/services/business-service', title: 'Business Immigration', type: 'link' },
        ]
    },
    {
        title: 'Express Entry', icon: Briefcase, type: 'sub', active: false, children: [
            { path: '/expressEntry/rounds_invitation', title: 'Rounds of Invitation', type: 'link' },
        ]
    },
    {
        title: 'Blog', icon: Edit, type: 'sub', active: false, children: [
            { path: '/blog/blogList', title: 'Blog List', type: 'link' },
            { path: '/blog/blogPost', title: 'Add Post', type: 'link' },
        ]
    },
    {
        title: 'Our Team', path: '/team/members', icon: Users, type: 'link', active: false
    },
]
