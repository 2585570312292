import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from "../../components/common/breadcrumb";
import RichEditor from '../../components/editor/editor';
import { ToastContainer, toast } from 'react-toastify';
import useServiceContents from '../../hooks/useServiceContents'


const ServicesEditTemplate = ({
    doc, service
}) => {
    // informations
    const [data, setData] = useServiceContents(doc || '')

    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')

    useEffect(() => {
        if (data) {
            setTitle(data?.title || '')
            setContent(data?.content || '')
        }
    }, [data])

    const handleDiscard = () => {
        setTitle(data?.title)
        setContent(data?.content)
        toast.success('Discarded!')
    }

    const handlePost = () => {
        // prepare data
        setData({
            title: title,
            content: content
        })
    }

    return (
        <Fragment>
            <Breadcrumb title={`${service} Service`} parent="Services" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{service} Edit</h5>
                            </div>
                            <div className="card-body add-post">
                                <form className="row needs-validation" noValidate="">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="post_title">Title override (English only):</label>
                                            <input
                                                className="form-control"
                                                id="post_title"
                                                value={title}
                                                onChange={e => setTitle(e.target.value)}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <div className="theme-form">
                                                <div className="form-group">
                                                    <label>Content:</label>
                                                    <RichEditor
                                                        content={content}
                                                        setContent={setContent}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="btn-showcase" style={{ marginTop: '1rem' }}>
                                    <button
                                        className='btn btn-primary'
                                        type="button"
                                        onClick={handlePost}
                                    >
                                        Save
                                    </button>
                                    <input
                                        className="btn btn-light"
                                        type="reset"
                                        value="Discard"
                                        onClick={handleDiscard}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </Fragment>
    );
};

export default ServicesEditTemplate;