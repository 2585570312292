
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import firebase from '../data/base';

const db = firebase.firestore();

const useAlertModal = () => {
    const [content, setContent] = useState('')

    const updateContent = (params) => {
        db.collection('client_home_alert').doc('alert_modal').update(params).then(() => {
            // set the updated content
            setContent(params)
            toast.success('Content updated!')
        }).catch((error) => {
            console.error(error)
            toast.error('Unable to update content!')
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            const docRef = db.collection('client_home_alert').doc('alert_modal');
            const doc = await docRef.get();
            if (doc.exists) {
                const data = doc.data();
                setContent(data);
            } else {
                console.error('Can not find doc of ', doc);
                toast.error('Unable to fetch alert modal from database!')
            }
        }
        fetchData()
    }, [])

    return [content, updateContent]
}

export default useAlertModal