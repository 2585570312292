import React, { useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './assets/css/Pe-icon-7-stroke.css';
import 'react-dropzone-uploader/dist/styles.css'
import 'react-bootstrap-typeahead/css/Typeahead.css';

//un-comment this auth by using firebase only
import app from './data/base';
import { useState } from 'react';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux**
import { Provider } from 'react-redux';
import store from './store/index';
import App from "./components/app";

// Import custom Components 

import Default from './components/dashboard/defaultCompo/default';

// Advance

import UserProfile from './components/users/userProfile';
import UserEdit from './components/users/userEdit';
import BlogList from './components/blog/blogList';
import BlogPost from './components/blog/blogPost';

import TeamMembers from './components/team/team.js';
import TeamUser from './components/team/team-user.js';

// Services
// import EditHomeServices from './pages/services/home-services/index';
import EditServices from './pages/services/edit-services';
import StudyPermitService from './pages/services/study-permit-service';
import CareGiverService from './pages/services/care-giver-service';
import ExpressEntryService from './pages/services/express-entery-service';

import RoundsInvitation from './pages/expressEntry/roundsInvitation';
import EditInvitation from './pages/expressEntry/edit-invitation';


import ComingSoon from './pages/comingsoon';
import ComingSoonImg from './pages/comingsoonImg';
import ComingSoonVideo from './pages/comingsoonVideo';
import Maintenance from './pages/maintenance';
import Error400 from './pages/errors/error400';
import Error401 from './pages/errors/error401';
import Error403 from './pages/errors/error403';
import Error404 from './pages/errors/error404';
import Error500 from './pages/errors/error500';
import Error503 from './pages/errors/error503';
import Signin from './auth/signin';

// Import Applications Components
// import Todo from './components/applications/todo-app/todo';
// import EmailDefault from './components/applications/email-app/emailDefault';
// import Chat from './components/applications/chat-app/chat';
// import EcommerceApp from './components/applications/ecommerce-app/product';
// import AddToCart from './components/applications/ecommerce-app/add-to-cart';
// import WishlistComponent from './components/applications/ecommerce-app/wishlist';
// import ProductDetail from './components/applications/ecommerce-app/product-detail/product-detail';
// import Invoice from './components/applications/ecommerce-app/invoice';
// import Checkout from './components/applications/ecommerce-app/checkout';
// import todoFirebase from './components/applications/todo-firebase-app/todo-firebase';
// import ContactApp from './components/applications/contact-app/contactApp';
// import NewUser from './components/applications/contact-app/new-user';
// import EditUser from './components/applications/contact-app/edit-user';
// import ProductList from './components/applications/ecommerce-app/product-list';
// import Payment from './components/applications/ecommerce-app/payment';
// import History from './components/applications/ecommerce-app/history';


//config data
import configDB from './data/customizer/config'
// dashboard
import HomeAlertModal from './pages/dashboard/home-alert';

// services
import FamilySponsorService from './pages/services/family-sponsor-service';
import CitizenshipService from './pages/services/citizenship-service';
import TemporaryVisaService from './pages/services/temporary-visa-service';
import NotaryService from './pages/services/notary-service';
import FingerPrintingService from './pages/services/fingerprinting-service';
import BusinessService from './pages/services/business-service';



//firebase Auth only then un-comment this current User code
function Root() {
    const abortController = new AbortController();
    const [currentUser, setCurrentUser] = useState(false);

    useEffect(() => {
        const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
        app.auth().onAuthStateChanged(setCurrentUser);

        document.body.classList.add(layout);

        console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
        console.disableYellowBox = true;

        return function cleanup() {
            abortController.abort();
        }

    }, [abortController]);

    return (
        <div className="App">
            <Provider store={store}>
                <BrowserRouter basename={`/`}>
                    <ScrollContext>
                        <Switch>
                            <Route path={`${process.env.PUBLIC_URL}/login`} component={Signin} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/comingsoon`} component={ComingSoon} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonImg`} component={ComingSoonImg} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonVideo`} component={ComingSoonVideo} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error404`} component={Error404} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503} />

                            {currentUser !== null ?
                                <Fragment>
                                    <App>
                                        {/* dashboard menu */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                            return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/main`} />)
                                        }} />
                                        {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Default} /> */}
                                        <Route path={`${process.env.PUBLIC_URL}/dashboard/main`} component={Default} />
                                        <Route path={`${process.env.PUBLIC_URL}/dashboard/home-alert`} component={HomeAlertModal} />

                                        {/* Users */}
                                        <Route path={`${process.env.PUBLIC_URL}/users/userProfile`} component={UserProfile} />
                                        <Route path={`${process.env.PUBLIC_URL}/users/userEdit`} component={UserEdit} />

                                        {/* Blog */}
                                        <Route path={`${process.env.PUBLIC_URL}/blog/blogList`} component={BlogList} />
                                        <Route path={`${process.env.PUBLIC_URL}/blog/blogPost`} component={BlogPost} />

                                        {/* Team */}
                                        <Route path={`${process.env.PUBLIC_URL}/team/members`} component={TeamMembers} />
                                        <Route path={`${process.env.PUBLIC_URL}/team/add-user`} component={TeamUser} />
                                        <Route path={`${process.env.PUBLIC_URL}/team/edit-user/:id`} component={TeamUser} />

                                        {/* Express Entry */}
                                        <Route path={`${process.env.PUBLIC_URL}/expressEntry/rounds_invitation`} component={RoundsInvitation} />
                                        <Route path={`${process.env.PUBLIC_URL}/expressEntry/add-invitation`} component={EditInvitation} />
                                        <Route path={`${process.env.PUBLIC_URL}/expressEntry/edit-invitation/:id`} component={EditInvitation} />

                                        {/* Services */}
                                        {/* <Route path={`${process.env.PUBLIC_URL}/services/home-services`} component={EditHomeServices} /> */}
                                        <Route path={`${process.env.PUBLIC_URL}/services/services`} component={EditServices} />
                                        <Route path={`${process.env.PUBLIC_URL}/services/study-permit`} component={StudyPermitService} />
                                        <Route path={`${process.env.PUBLIC_URL}/services/care-giver`} component={CareGiverService} />
                                        <Route path={`${process.env.PUBLIC_URL}/services/express-entry`} component={ExpressEntryService} />
                                        <Route path={`${process.env.PUBLIC_URL}/services/family-sponsor`} component={FamilySponsorService} />
                                        <Route path={`${process.env.PUBLIC_URL}/services/citizenship`} component={CitizenshipService} />
                                        <Route path={`${process.env.PUBLIC_URL}/services/temporary-visa`} component={TemporaryVisaService} />
                                        <Route path={`${process.env.PUBLIC_URL}/services/notary-public`} component={NotaryService} />
                                        <Route path={`${process.env.PUBLIC_URL}/services/fingerprinting`} component={FingerPrintingService} />
                                        <Route path={`${process.env.PUBLIC_URL}/services/business-service`} component={BusinessService} />
                                        {/* <Route path={`${process.env.PUBLIC_URL}/services/fingerPrint`} component={TeamMembers} />
                                        <Route path={`${process.env.PUBLIC_URL}/services/familySponsor`} component={TeamMembers} />
                                        <Route path={`${process.env.PUBLIC_URL}/services/backgroundCheck`} component={TeamMembers} /> */}

                                    </App>
                                </Fragment>
                                :
                                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                            }
                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </Provider>
        </div>
    );
}


ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();