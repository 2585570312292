import React, { Fragment, useState, useEffect } from 'react';
import classnames from 'classnames';
import Breadcrumb from "../common/breadcrumb";
import RichEditor from '../editor/editor';
import { ToastContainer, toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { UncontrolledAlert } from 'reactstrap';
import ImageUploader from 'react-images-upload';
import Dropzone from 'react-dropzone-uploader'
import firebase from '../../data/base';
import { submitBlog, saveBlog, uploadAttachment, uploadImg } from '../../services/blog.service';

const BlogPost = ({ location, history }) => {
    // informations
    const [blogID, setBlogID] = useState(null); // use for editting post
    const [isEdit, setIsEdit] = useState(false);

    const [categoryOption, setCategoryOption] = useState([]);
    const [dropDisabled, setDropDisabled] = useState(false);

    // constants
    const MAX_FILES = 3;
    const TITLE_LENGTH = {
        min: 3,
        max: 128
    };
    const SUMMARY_LENGTH = {
        min: 3,
        max: 256
    };

    // fields
    const [postTitle, setPostTitle] = useState('');
    const [postSummary, setPostSummary] = useState('');
    const [postCategory, setCategory] = useState([])
    const [content, setContent] = useState('');
    const [imageUploaded, setImageUploaded] = useState(null)
    const [fileUploaded, setFileUploaded] = useState([]);
    
    // require post and summary
    const POST_DISABLE_RULE =
        (postTitle.length < TITLE_LENGTH.min || postTitle.length > TITLE_LENGTH.max)
        || (postSummary.length < SUMMARY_LENGTH.min || postSummary.length > SUMMARY_LENGTH.max);

    // ======== EDIT ==========
    // wait for blog id or redirect
    useEffect(() => {
        const state = location.state;
        // check if state is available in route
        if (state) {
            const { isEdit, id } = state;
            // check if is edit
            if (isEdit) {
                // save is edit
                setIsEdit(isEdit);
                // check if id is available
                if (id) {
                    setBlogID(location.state.id);
                } else {
                    // redirect if not
                    console.error('Blog id does not exist: ', location);
                    // return to list
                    history.push(`${process.env.PUBLIC_URL}/blog/blogList`);
                }
            }
        }


    }, [location.state]);

    // fetch post informations with ID and when it is edit
    useEffect(() => {
        const fetchData = async () => {

            const docRef = firebase.firestore().collection('article_blog').doc(blogID || '');
            const doc = await docRef.get();
            if (doc.exists) {
                // pre fill all the fields
                const data = doc.data();
                setPostTitle(data.title);
                setCategory(data.category);
                setPostSummary(data.summary);
                setImageUploaded(data.preview_img);
                setContent(data.body);
                // attachment will be replaced anyways if state changes
                // setFileUploaded(data.attachments);
            } else {
                console.error('Can not find ID of ', blogID);
                history.push(`${process.env.PUBLIC_URL}/blog/blogList`);
            }
        }

        if (isEdit && blogID) {
            fetchData();
        }

    }, [isEdit, blogID]);
    // ======== END EDIT ==========

    // fetch category
    useEffect(() => {
        const fetchCategory = async () => {
            const docRef = firebase
                .firestore()
                .collection('app_config')
                .doc('common');
            const snapshot = await docRef.get();

            if (snapshot.exists) {
                const data = snapshot.data();
                setCategoryOption(data.category || []);
            } else {
                console.error('Can not find category information.');
            }

        };

        fetchCategory();

    }, []);


    const handleDiscard = () => {
        if (isEdit) {
            history.push(`${process.env.PUBLIC_URL}/blog/blogList`)
        } else {
            setPostTitle('');
            setPostSummary('');
            setCategory([]);
            setContent('');
        }
    }

    const handlePost = () => {
        // prepare data
        const postField = {
            title: postTitle,
            preview_img: imageUploaded,
            summary: postSummary,
            category: postCategory,
            body: content,
            attachments: fileUploaded
        };
        if (isEdit) {
            // edit post
            saveBlog(
                blogID, postField,
                {
                    onComplete: () => {
                        handleDiscard();
                        toast.success(`Edited blog`)
                    },
                    onError: err => toast.error('Failed to save!')
                }
            );
        } else {
            // publish blog
            submitBlog(
                postField,
                {
                    onComplete: () => {
                        toast.success(`Submitted. Please review and publish.`)
                        history.push(`${process.env.PUBLIC_URL}/blog/blogList`);
                    },
                    onError: err => toast.error('Failed to submit!')
                }
            );
        }
    }

    // handle file submit
    const handleFileSubmit = (files, allFiles) => {
        const fileMetas = [];
        // set drop box to be disabled
        setDropDisabled(true);
        // start upload attachment
        uploadAttachment(allFiles, {
            onEachComplete: meta => {
                fileMetas.push({
                    contentType: meta.contentType,
                    fullPath: meta.fullPath,
                    name: meta.name,
                    size: meta.size,
                    type: meta.type,
                    timeCreated: meta.timeCreated,
                    updated: meta.updated
                });
                setDropDisabled(false);
                toast.success(`${meta.name} uploaded!`)
            },
            onComplete: () => { setFileUploaded(fileMetas) },   // set all the uploaded files
            onError: err => {
                switch (err.code) {
                    case 'storage/unauthorized':
                        toast.error('No permission to upload!')
                        break;

                    case 'storage/canceled':
                        toast.error('Upload canceled!')
                        break;
                    case 'storage/unknown':
                        console.error("Attachment upload error: ");
                        console.error(err.serverResponse);
                        toast.error('Unknown error!');
                        break;
                }
            }
        });
    }

    const handleOnImageDrop = picture => {
        if (picture && picture.length > 0) {
            let fileMetas = null;
            // start upload image , first in the list
            uploadImg(picture[0], {
                onEachComplete: meta => {
                    // save meta
                    fileMetas = {
                        contentType: meta.contentType,
                        fullPath: meta.fullPath,
                        name: meta.name,
                        size: meta.size,
                        type: meta.type,
                        timeCreated: meta.timeCreated,
                        updated: meta.updated
                    };

                    toast.success(`${meta.name} uploaded!`)
                },
                onComplete: () => {
                    setImageUploaded(fileMetas)
                },   // set uploaded img metas
                onError: err => {
                    switch (err.code) {
                        case 'storage/unauthorized':
                            toast.error('No permission to upload!')
                            break;
                        case 'storage/canceled':
                            toast.error('Upload canceled!')
                            break;
                        case 'storage/unknown':
                            console.error("Image upload error: ");
                            console.error(err.serverResponse);
                            toast.error('Unknown error!');
                            break;
                    }
                }
            });
        }
    }

    return (
        <Fragment>
            <Breadcrumb title="Add Post" parent="Blog" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">

                            <div className="card-header">
                                <h5>Post Edit</h5>
                            </div>
                            <div className="card-body add-post">
                                <form className="row needs-validation" noValidate="">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="post_title">Title (max {TITLE_LENGTH.max}, current: {postTitle.length}):</label>
                                            <input
                                                className="form-control"
                                                id="post_title"
                                                value={postTitle}
                                                onChange={e => setPostTitle(e.target.value)}
                                                type="text"
                                                placeholder={`Required title (min ${SUMMARY_LENGTH.min}, max ${SUMMARY_LENGTH.max})`}
                                                required="required"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="post_summary">Summary (Homepage and SEO) (max {SUMMARY_LENGTH.max}, current: {postSummary.length}):</label>
                                            <input
                                                className="form-control"
                                                id="post_summary"
                                                value={postSummary}
                                                onChange={e => setPostSummary(e.target.value)}
                                                type="text"
                                                placeholder={`Required summary (min ${SUMMARY_LENGTH.min}, max ${SUMMARY_LENGTH.max})`}
                                                required="required"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Category: </label>
                                            <Typeahead
                                                id="multiple-typeahead"
                                                clearButton
                                                selected={postCategory}
                                                onChange={selected => setCategory(selected)}
                                                labelKey="name"
                                                multiple
                                                options={categoryOption}
                                                placeholder="Select Category...."
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="theme-form">
                                                <div className="form-group">
                                                    {
                                                        isEdit ?
                                                            <UncontrolledAlert color="warning" fade={false}>
                                                                The preview image below will REPLACE with current.
                                                                If no changes made, the original image will remain the same.
                                                        </UncontrolledAlert> : null
                                                    }
                                                    <label>Preview Image:</label>
                                                    <ImageUploader
                                                        withIcon={false}
                                                        withPreview={true}
                                                        label=""
                                                        singleImage={true}
                                                        buttonText="Upload Images"
                                                        onChange={handleOnImageDrop}
                                                        imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif", ".svg"]}
                                                        maxFileSize={52428800}
                                                        fileSizeError=" file size is too big"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="email-wrapper">
                                            <div className="theme-form">
                                                <div className="form-group">
                                                    <label>Content:</label>
                                                    <RichEditor
                                                        content={content}
                                                        setContent={setContent}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="m-0 dz-message needsclick">
                                    {isEdit ?
                                        <UncontrolledAlert color="warning" fade={false}>
                                            The attachments below will REPLACE with current.
                                            If no changes made, the original attachment will remain the same.
                                    </UncontrolledAlert> : null}
                                    <Dropzone
                                        disabled={dropDisabled}
                                        onSubmit={handleFileSubmit}
                                        maxFiles={MAX_FILES}
                                        multiple={true}
                                        canCancel={false}
                                        inputContent="Drop A File"
                                        styles={{
                                            dropzone: { width: '100%', height: 225 },
                                            dropzoneActive: { borderColor: 'green' },
                                        }}
                                    />
                                </div>
                                <div className="btn-showcase" style={{ marginTop: '1rem' }}>
                                    <button
                                        className={
                                            classnames('btn btn-primary', {
                                                disabled: POST_DISABLE_RULE
                                            })
                                        }
                                        type="button"
                                        onClick={!POST_DISABLE_RULE ? handlePost : null}
                                    >
                                        {isEdit ? 'Save Post' : 'Submit for preview'}</button>
                                    <input
                                        className="btn btn-light"
                                        type="reset"
                                        value="Discard"
                                        onClick={handleDiscard}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </Fragment>
    );
};

export default BlogPost;